import {
  BlogHeader,
  Categories,
  CategoryFilter,
  StyledHeaderSection,
} from 'sections/Blog/styles'
import { color } from 'const/colors'
import React, { useCallback, useContext } from 'react'
import { BlogContext } from 'pages/blog'

const BlogHeaderSection = () => {
  const {
    title,
    categories,
    selectedCategory,
    setSelectedCategory,
  } = useContext(BlogContext)

  const handleCategoryFilterClick = useCallback(
    (category) =>
      setSelectedCategory((previousValue) =>
        previousValue === category ? null : category
      ),
    [setSelectedCategory]
  )

  return (
    <BlogHeader>
      <StyledHeaderSection
        header
        headline={title.value}
        maxWidth={'910px'}
        width={'75%'}
      />
      <Categories>
        {categories.map((category) => (
          <CategoryFilter
            selected={selectedCategory === category}
            key={category}
            color={color.primary.o100}
            secondaryColor={color.primary.o100}
            onClick={() => handleCategoryFilterClick(category)}
          >
            {category}
          </CategoryFilter>
        ))}
      </Categories>
    </BlogHeader>
  )
}

export default React.memo(BlogHeaderSection)
