import { useEffect } from 'react'

const useIntersectionObserver = (callback, ref) => {
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    }

    const observer = new IntersectionObserver((entities) => {
      const [target] = entities
      if (target.isIntersecting) {
        callback()
      }
    }, options)

    if (ref.current) {
      observer.observe(ref.current)
    }
  }, [])
}

export default useIntersectionObserver
