import {
  BlogArticlesGrid,
  BlogArticles,
  CardContent,
  CardMedia,
  DateTime,
  LoadMore,
  Paragraph,
  Title,
  StyledCardContainer,
  InnerCard,
  Category,
} from 'sections/Blog/styles'
import dayjs from 'dayjs'
import React, { useCallback, useContext, useRef } from 'react'
import { BlogContext } from 'pages/blog'
import LightBulb from 'components/Icons/LightBulb'
import PropTypes from 'prop-types'
import useIntersectionObserver from 'sections/Blog/hooks/useIntersectionObserver'
import PageTransitionLink from 'components/PageTransitionLink'

export const pageSize = 12

const Card = React.memo(({ children, category }) => (
  <StyledCardContainer>
    {category && (
      <Category>
        <LightBulb size={16} />
        {category}
      </Category>
    )}
    <InnerCard>{children}</InnerCard>
  </StyledCardContainer>
))

Card.displayName = 'Card'

Card.propTypes = {
  children: PropTypes.array,
  category: PropTypes.string,
}

const BlogArticlesSection = () => {
  const { articles, loadMore, canLoadMore } = useContext(BlogContext)

  const loader = useRef()

  const loadMoreIfAvailable = useCallback(() => canLoadMore && loadMore(), [
    canLoadMore,
    loadMore,
  ])

  useIntersectionObserver(loadMoreIfAvailable, loader)

  return (
    <BlogArticles>
      <BlogArticlesGrid>
        {articles.map((article, index) => (
          <PageTransitionLink
            key={article.elements.slug.value + index}
            to={`/blog/${article.elements.slug.value}`}
          >
            <Card
              category={
                article.elements.blog_categories.value[0]
                  ? article.elements.blog_categories.value[0].name
                  : ''
              }
            >
              <CardMedia
                style={{
                  backgroundImage: `url(${article.elements.preview_image.value[0].url})`,
                }}
              />
              <CardContent>
                <Title>{article.elements.title.value}</Title>
                <DateTime>
                  {dayjs(article.elements.date.value).format('MMMM Do, YYYY')}
                </DateTime>
                <Paragraph
                  dangerouslySetInnerHTML={{
                    __html: article.elements.preview_text.value,
                  }}
                />
              </CardContent>
            </Card>
          </PageTransitionLink>
        ))}
      </BlogArticlesGrid>
      {canLoadMore && (
        <LoadMore ref={loader} onClick={loadMore}>
          Load more
        </LoadMore>
      )}
    </BlogArticles>
  )
}

export default React.memo(BlogArticlesSection)
