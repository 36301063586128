import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import Layout from 'layouts/Default'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { BlogArticlesWrapper, PageContainer } from 'sections/Blog/styles'
import BlogHeaderSection from 'sections/Blog/header'
import BlogArticlesSection, { pageSize } from 'sections/Blog/articles'
import usePaginatedBlog from 'sections/Blog/hooks/usePaginatedBlog'

dayjs.locale('en')
dayjs.extend(advancedFormat)

export const BlogContext = React.createContext({})

const Blog = ({ data, ...restProps }) => {
  const { title } = data.kenticoCloudItemBlogSummary.elements
  const sourceArticles = data.allKenticoCloudItemBlogArticle.nodes

  const categories = useMemo(
    () => [
      ...new Set(
        sourceArticles.map((article) =>
          article.elements.blog_categories.value[0]
            ? article.elements.blog_categories.value[0].name
            : ''
        )
      ),
    ],
    [sourceArticles]
  )

  const {
    articles,
    loadMore,
    canLoadMore,
    setSelectedCategory,
    selectedCategory,
  } = usePaginatedBlog(sourceArticles, null, pageSize)

  const buildOGMetadata = useCallback(() => {
    const { elements } = data.kenticoCloudItemBlogSummary

    return {
      title: elements.page_metadata__opengraph_title.value,
      imageUrl: elements.page_metadata__opengraph_image.value.url,
      description: elements.page_metadata__opengraph_description.value,
    }
  })

  return (
    <Layout {...restProps} ogMetadata={buildOGMetadata()}>
      <Helmet>
        <link
          rel="canonical"
          href={`${process.env.GATSBY_ROBOTS_SITE_URL}/blog`}
        />
      </Helmet>
      <PageContainer>
        <BlogContext.Provider
          value={{
            articles,
            loadMore,
            canLoadMore,
            categories,
            title,
            selectedCategory,
            setSelectedCategory,
          }}
        >
          <BlogHeaderSection />
          <BlogArticlesWrapper>
            <BlogArticlesSection />
          </BlogArticlesWrapper>
        </BlogContext.Provider>
      </PageContainer>
    </Layout>
  )
}

Blog.propTypes = {
  data: PropTypes.object,
}

export default Blog

export const query = graphql`
  query Blog {
    kenticoCloudItemBlogSummary {
      elements {
        title {
          value
        }
        page_metadata__opengraph_title {
          value
        }
        page_metadata__opengraph_image {
          name
          type
          assets {
            url
            name
          }
          value {
            name
            type
            size
            url
            width
            height
          }
        }
        page_metadata__opengraph_description {
          value
        }
      }
    }
    allKenticoCloudItemBlogArticle(
      sort: { fields: elements___date___value, order: DESC }
    ) {
      nodes {
        elements {
          date {
            value
          }
          page_metadata__opengraph_title {
            name
            value
            type
            text
          }
          preview_image {
            name
            value {
              url
            }
          }
          page_metadata__opengraph_image {
            value {
              url
            }
          }
          preview_text {
            value
          }
          slug {
            value
          }
          title {
            value
          }
          blog_categories {
            value {
              name
            }
          }
        }
      }
    }
  }
`
