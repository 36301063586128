import { useCallback, useState } from 'react'
import { pageSize } from 'sections/Blog/articles'

const filterArticles = (articles, category) =>
  articles.filter(
    (article) =>
      category === null ||
      article.elements.blog_categories.value[0].name === category
  )

const sliceArticles = (articles, articlesToDisplay) =>
  articles.slice(0, articlesToDisplay)

const usePaginatedBlog = (articles, category, articlesToDisplay) => {
  const [pagesToDisplay, setPagesToDisplay] = useState(articlesToDisplay)
  const [selectedCategory, setSelectedCategory] = useState(category)

  const loadMore = useCallback(
    () => setPagesToDisplay((previousValue) => previousValue + pageSize),
    [setPagesToDisplay]
  )

  const canLoadMore =
    pagesToDisplay <
    articles.filter(
      (article) =>
        selectedCategory === null ||
        article.elements.blog_categories.value[0].name === selectedCategory
    ).length

  return {
    articles: sliceArticles(
      filterArticles(articles, selectedCategory),
      pagesToDisplay
    ),
    setSelectedCategory,
    selectedCategory,
    loadMore,
    canLoadMore,
  }
}

export default usePaginatedBlog
